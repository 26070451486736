import React, { FC } from 'react';

import Slider from 'react-slick';
import { BlockCarouselProps } from './model';
import './BlockCarousel.scss';

const defaultSettings = {
  dots: false,
  infinite: false,
  arrows: true,
  accessibility: true,
  autoplay: false,
};
const BlockCarousel: FC<BlockCarouselProps> = ({ settings = {}, children }) => {
  const curSettings = { ...defaultSettings, ...settings };

  return (
    <div className="block-carousel">
      <Slider {...curSettings}>{children}</Slider>
    </div>
  );
};

export default BlockCarousel;
