import React, { FC } from 'react';
import Icon from 'common/IconCustom';
import Button from 'common/Button';
import { ICustomArrow } from '../models';

const PrevArrow: FC<ICustomArrow> = ({
  className,
  onClick,
  customClassName,
  ariaLabel,
  customIcon,
  iconColorCount,
}) => {
  const disabled = className.includes('disabled');

  return (
    <Button
      disabled={disabled}
      classes={`${className} ${customClassName}`}
      clickHandler={onClick}
      variant="secondary"
      ariaLabel={ariaLabel}
    >
      <Icon icon={customIcon || 'chevron-left'} colorCount={iconColorCount || 1} />
    </Button>
  );
};

export default PrevArrow;
